import axios from "axios";
import authHeader from '../middleware/auth-header';
import authHeaderImage from '../middleware/auth-header-img';

export default {
    namespaced: true,
    state: {
        bills: [],
        susuk: [],
        total: "",
    },
    getters: {
        bills(state) {
            return state.bills;
        },
        total(state) {
            return state.total;
        },
        susuk(state) {
            return state.susuk;
        },
    },
    mutations: {
        SET_BILLS(state, value) {
            state.bills = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        SET_SUSUK(state, value) {
            state.susuk = value;
        },
    },
    actions: {
        async bills({ commit }, credentials) {
            await axios.post("/guide/auth/booking/bills",credentials, { "headers": authHeader() })
            .then((r) => {
                commit("SET_BILLS", r.data.data);
            }).catch ((e) => {
                console.log(e.response)
            })
        },

        async susuk({ commit }, credentials) {
            await axios.post("/guide/auth/booking/susuk",credentials, { "headers": authHeader() })
            .then((r) => {
                commit("SET_SUSUK", r.data.data);
            }).catch ((e) => {
                console.log(e.response)
            })
        },
        
        async totalTicket({ commit }, credentials) {
            await axios.post("/guide/auth/booking/bills/total/ticket",credentials, { "headers": authHeader() })
            .then((r) => {
                commit("SET_TOTAL", r.data.total);
            }).catch ((e) => {
                console.log(e.response)
            })
        },

        async upload(_, credentials) {
            await axios.post("/guide/auth/booking/bill/upload",credentials, { "headers": authHeaderImage() })
            .then(() => {
                console.log('success')
            }).catch ((e) => {
                console.log(e.response)
            })
        },
        async delBill(_, credentials) {
            await axios.post("/guide/auth/booking/bill/delete",credentials, { "headers": authHeader() })
            .then(() => {
                console.log('success');
            }).catch ((e) => {
                console.log(e.response)
            })
        },
    },
};
