import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import dashboard from "./dashboard";
import tour from "./tour";
import bill from "./bill";
import destination from "./destination";
import booking from "./booking";
import trx from "./trx";
import availability from "./availability";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        dashboard,
        tour,
        bill,
        destination,
        booking,
        trx,
        availability,
    },
});
