import axios from "axios";
import authHeader from '../middleware/auth-header';

export default {
    namespaced: true,
    state: {
        trxs: [],
        total: "",
    },
    getters: {
        trxs(state) {
            return state.trxs;
        },
        total(state) {
            return state.total;
        },
    },
    mutations: {
        SET_TRXS(state, value) {
            state.trxs = value;
        },

        SET_TOTAL(state, value) {
            state.total = value;
        },
    },
    actions: {
        async trxs({ commit }, credentials) {
            await axios.post("/guide/auth/transactions",credentials, { "headers": authHeader() })
            .then((r) => {
                commit("SET_TRXS", r.data.data);
            }).catch ((e) => {
                console.log(e.response)
            })
        },

        async getTotal({ commit }, credentials) {
            await axios.post("/guide/auth/transactions/total",credentials, { "headers": authHeader() })
            .then((r) => {
                commit("SET_TOTAL", r.data);
            }).catch ((e) => {
                console.log(e.response)
            })
        },
    },
};
