import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import './index.css'

Vue.use(CKEditor);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.config.productionTip = false;

Vue.prototype.$store = store

store.dispatch("auth/getAuth").finally(() => {
    new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
    }).$mount("#app");
});
