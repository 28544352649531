import axios from "axios";
import authHeader from '../middleware/auth-header';

export default {
    namespaced: true,
    state: {
        destinations: [],
        destination: "",
    },
    getters: {
        destinations(state) {
            return state.destinations;
        },
        destination(state) {
            return state.destination;
        },
    },
    mutations: {
        SET_DESTINATIONS(state, value) {
            state.destinations = value;
        },
        SET_DESTINATION(state, value) {
            state.destination = value;
        },
    },
    actions: {
        async destinations({ commit }, credentials) {
            await axios.post("/guide/auth/destinations",credentials, { "headers": authHeader() })
            .then((r) => {
                commit("SET_DESTINATIONS", r.data.data);
            }).catch ((e) => {
                console.log(e.response)
            })
        },

        async find({ commit }, credentials) {
            await axios.post("/guide/auth/destination/find",credentials, { "headers": authHeader() })
            .then((r) => {
                commit("SET_DESTINATION", r.data.data);
            }).catch ((e) => {
                console.log(e.response)
            })
        },
    },
};
