import axios from "axios";
import authHeader from '../middleware/auth-header';

export default {
    namespaced: true,
    state: {
        
    },
    getters: {
        
    },
    mutations: {
        
    },
    actions: {
        async requestComplete(_, credentials) {
            await axios.post("/guide/auth/tour/completed",credentials, { "headers": authHeader() })
            .then(() => {
                console.log('success');
            }).catch ((e) => {
                console.log(e.response)
            })
        },
    },
};
