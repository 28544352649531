<template>
    <v-app light dense>
        <v-navigation-drawer
            v-model="drawer"
            :mini-variant="miniVariant"
            :clipped="clipped"
            fixed
            app
            dense
            color="#182A4D">
            <v-list-item>
                <v-list-item-content class="py-7">
                    <v-list-item-title class="text-h5 white--text">
                        <v-img
                            src="../assets/logo_hbd.png"
                            max-width="100"></v-img>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list>
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    :to="item.to"
                    router
                    exact
                    dense>
                    <v-list-item-action>
                        <v-icon color="#ddd">{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="white--text">{{
                            item.title
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar :clipped-left="clipped" dense fixed app elevation="0">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

            <!-- <v-toolbar-title>{{ title }}</v-toolbar-title> -->
            <v-spacer />
            <v-btn icon @click="signout()">
                <v-icon>mdi-power</v-icon>
            </v-btn>
        </v-app-bar>
        <v-main>
            <v-container>
                <router-view />
            </v-container>
        </v-main>

        <div class="text-center ma-2">
            <v-snackbar v-model="snackbar" top right :color="color">
                {{ error }}
            </v-snackbar>
        </div>
    </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    middleware: "auth",
    name: "DefaultLayout",
    data() {
        return {
            clipped: false,
            drawer: false,
            fixed: false,
            timeout: 3000,
            items: [
                {
                    icon: "mdi-apps",
                    title: "Beranda",
                    to: "/v1/dashboard",
                },
                {
                    icon: "mdi-book-edit",
                    title: "Tours",
                    to: "/v1/tours",
                },
                {
                    icon: "mdi-book-edit",
                    title: "Availabilities",
                    to: "/v1/availabilities",
                },
            ],
            miniVariant: false,
            right: true,
            rightDrawer: false,
            title: "HBD Guide System v.1.0.0",
        };
    },

    mounted() {
        document.title = "HBD Guide System";
    },

    computed: {
        ...mapGetters({
            check: "auth/check",
            user: "auth/user",
            snackbar: "auth/snackbar",
            error: "auth/error",
            color: "auth/color",
        }),
    },

    methods: {
        ...mapActions({
            logoutAction: "auth/logout",
            getSnackbar: "auth/snackbar",
        }),

        async signout() {
            await this.logoutAction();
            this.$router.replace("/");
        },

        snackbarClose() {
            this.getSnackbar();
        },
    },
};
</script>
