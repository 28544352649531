import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "@/layouts/DefaultLayout.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "login",
        component: () =>
            import(
                /* webpackChunkName: "login" */ "../views/auth/LoginView.vue"
            ),
    },
    {
        path: "/forgot",
        name: "forgot",
        component: () =>
            import(
                /* webpackChunkName: "forgot" */ "../views/auth/ForgotView.vue"
            ),
    },
    {
        path: "/v1",
        component: DashboardLayout,
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/main/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },

            {
                path: "profile",
                name: "profile",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/profile/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },

            {
                path: "tours",
                name: "tours",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/tours/IndexTour.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "tours/:id",
                name: "tours-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/tours/DetailTour.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "tours/upload/bill/:id",
                name: "upload-bill",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/tours/UploadBill.vue"
                    ),
                meta: {
                    auth: true,
                },
            },

            // TRX 
            {
                path: "transactions",
                name: "transactions",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/trx/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },

            {
                path: "availabilities",
                name: "availabilities",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/availabilities/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.auth && !store.getters["auth/check"]) {
        next({ name: "login" });
    } else {
        next();
    }

    if (to.name == "login" && store.getters["auth/check"]) {
        next({ name: "dashboard" });
    } else {
        next();
    }
});

export default router;
