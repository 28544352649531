import axios from "axios";
import authHeader from '../middleware/auth-header';

export default {
    namespaced: true,
    state: {
        bookings: [],
        booking: "",
        total: 0,
    },
    getters: {
        bookings(state) {
            return state.bookings;
        },
        total(state) {
            return state.total;
        },
        booking(state) {
            return state.booking;
        },
    },
    mutations: {
        SET_BOOKINGS(state, value) {
            state.bookings = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        SET_BOOKING(state, value) {
            state.booking = value;
        },
    },
    actions: {
        async bookings({ commit }, credentials) {
            await axios.post("/guide/auth/bookings",credentials, { "headers": authHeader() })
            .then((r) => {
                commit("SET_BOOKINGS", r.data.data.data);
                commit("SET_TOTAL", r.data.data.total);
            }).catch ((e) => {
                console.log(e.response)
            })
        },

        async booking({ commit }, credentials) {
            await axios.post("/guide/auth/booking/detail",credentials, { "headers": authHeader() })
            .then((r) => {
                commit("SET_BOOKING", r.data.data);
            }).catch ((e) => {
                console.log(e.response)
            })
        },

        async guideApproved(_, credentials) {
            await axios.post("/guide/auth/booking/guide/approved",credentials, { "headers": authHeader() })
            .then(() => {
                console.log('approved')
            }).catch ((e) => {
                console.log(e.response)
            })
        },

        async guideRejected(_, credentials) {
            await axios.post("/guide/auth/booking/guide/rejected",credentials, { "headers": authHeader() })
            .then(() => {
                console.log('rejected')
            }).catch ((e) => {
                console.log(e.response)
            })
        },
    },
};
