<template>
    <v-app>
        <v-main class="mainHbd">
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",

    data: () => ({
        //
    }),
};
</script>

<style>
.mainHbd {
    padding: 0 !important;
}
body {
    font-family: "Poppins", Arial, sans-serif !important;
}
</style>
