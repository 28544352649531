import axios from "axios";
import authHeader from '../middleware/auth-header';

export default {
    namespaced: true,
    state: {
        authenticated: false,
        user: [],
        snackbar: false,
        error: "",
        color: "",
    },
    getters: {
        check(state) {
            return state.authenticated;
        },
        user(state) {
            return state.user;
        },
        snackbar(state) {
            return state.snackbar;
        },
        error(state) {
            return state.error;
        },
        color(state) {
            return state.color;
        },
    },
    mutations: {
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value;
        },
        SET_USER(state, value) {
            state.user = value;
        },
        SET_SNACKBAR(state) {
            state.snackbar = true;
            setTimeout(() => {
                state.snackbar = false;
            }, 5000);
        },
        SET_ERROR(state, value) {
            state.error = value.msg;
            state.color = value.color;
        },
    },
    actions: {
        async login({ commit }, credentials) {
            await axios.get("/sanctum/csrf-cookie");
            let response = await axios.post("/guide/login", credentials);
            // let response = await axios.get("/guide/user");

            localStorage.setItem("user", response.data.user);
            localStorage.setItem("token", response.data.access_token);

            // dispatch("me");
            commit("SET_AUTHENTICATED", true);
            commit("SET_USER", response.data.user);
        },

        async me({ commit }) {
            try {
                let response = await axios.get("/api/user");
                commit("SET_AUTHENTICATED", true);
                commit("SET_USER", response.data.data);
            } catch (e) {
                commit("SET_AUTHENTICATED", false);
                commit("SET_USER", []);
            }
        },

        async getAuth({ commit }) {
            await axios.post("/guide/auth/me","", { "headers": authHeader() })
            .then((response) => {
                console.log(response.data.data);
                commit("SET_AUTHENTICATED", true);
                commit("SET_USER", response.data.data);
            }).catch ((e) => {
                console.log(e.response)
                commit("SET_AUTHENTICATED", false);
                commit("SET_USER", []);
            })
        },

        async changePassword(_, credentials) {
            await axios.post("/guide/auth/profile/update/password",credentials, { "headers": authHeader() })
            .then(() => {
            }).catch ((e) => {
                console.log(e.response)
            })
        },

        async logout({ commit }) {
            // await axios.post("/logout");
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            commit("SET_AUTHENTICATED", false);
            commit("SET_USER", []);
        },

        snackbar({ commit }, credentials) {
            commit("SET_ERROR", credentials);
            commit("SET_SNACKBAR");
        },
    },
};
