import axios from "axios";
import authHeader from '../middleware/auth-header';

export default {
    namespaced: true,
    state: {
        availabilities: [],
    },
    getters: {
        availabilities(state) {
            return state.availabilities;
        },
    },
    mutations: {
        SET_availabilities(state, value) {
            state.availabilities = value;
        },
    },
    actions: {
        async availabilities({ commit }, credentials) {
            await axios.post("/guide/auth/availability",credentials, { "headers": authHeader() })
            .then((r) => {
                commit("SET_availabilities", r.data.data.data);
            }).catch ((e) => {
                console.log(e.response)
            })
        },
        async delete(_, credentials) {
            await axios.post("/guide/auth/availability/delete",credentials, { "headers": authHeader() })
            .then((r) => {
                console.log(r)
            }).catch ((e) => {
                console.log(e.response)
            })
        },
        async add(_, credentials) {
            await axios.post("/guide/auth/availability/add",credentials, { "headers": authHeader() })
            .then((r) => {
                console.log(r)
            }).catch ((e) => {
                console.log(e.response)
            })
        },
    },
};
